<template>
  <div class="change_bg">
    <Navbar></Navbar>
    <div class="container">
      <div class="manage_link">
        <div class="m_link1">
          <router-link to="/">  {{$store.state.language.lang[1]}}</router-link>
        </div>
        <div>
          <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M0.106862 0.191111C0.277469 -0.0260253 0.591796 -0.0637446 0.808932 0.106862L7.80893 5.60686C7.92958 5.70165 8.00002 5.84659 8.00002 6.00002C8.00002 6.15345 7.92958 6.29839 7.80893 6.39318L0.808932 11.8932C0.591796 12.0638 0.277469 12.0261 0.106862 11.8089C-0.0637446 11.5918 -0.0260253 11.2775 0.191111 11.1069L6.69073 6.00002L0.191111 0.89318C-0.0260253 0.722574 -0.0637446 0.408246 0.106862 0.191111Z"
                  fill="#89899F"/>
          </svg>
        </div>
        <div class="m_link1 m_left">
          <router-link to="/opendata"> {{$store.state.language.lang[183]}}</router-link>
        </div>
        <div>
          <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M0.106862 0.191111C0.277469 -0.0260253 0.591796 -0.0637446 0.808932 0.106862L7.80893 5.60686C7.92958 5.70165 8.00002 5.84659 8.00002 6.00002C8.00002 6.15345 7.92958 6.29839 7.80893 6.39318L0.808932 11.8932C0.591796 12.0638 0.277469 12.0261 0.106862 11.8089C-0.0637446 11.5918 -0.0260253 11.2775 0.191111 11.1069L6.69073 6.00002L0.191111 0.89318C-0.0260253 0.722574 -0.0637446 0.408246 0.106862 0.191111Z"
                  fill="#89899F"/>
          </svg>
        </div>
        <div class="m_link2">
          <router-link to="/">
            {{$store.state.opendataid.title}}
          </router-link>
        </div>

      </div>
      <div class="row">
        <div class=" col-12">
          <div class="m_body_title">
            <h2>{{$store.state.opendataid.title}}</h2>
          </div>
        </div>
      </div>
      <div class="opensingcard">
        <table>
          <tr>
            <th>№</th>
            <th>{{$store.state.language.lang[50]}}</th>
            <th>Tavsifning mazmuni</th>
          </tr>
          <tr>
            <td>1</td>
            <td>Ma'lumotning identifikatsion (xos) raqami</td>
            <td>{{$store.state.opendataid.id_number}}</td>
          </tr>
          <tr>
            <td>2</td>
            <td>Ma'lumotlarning nomi</td>
            <td>{{$store.state.opendataid.title}}</td>
          </tr>
          <tr>
            <td>3</td>
            <td>Ma'lumotlarning mazmuni</td>
            <td>{{$store.state.opendataid.description}}</td>
          </tr>
          <tr>
            <td>4</td>
            <td>Ma'lumotlarning egasi</td>
            <td>{{$store.state.opendataid.owner}}</td>
          </tr>
          <tr>
            <td>5</td>
            <td>Ma'lumotlar</td>
            <td>
              <a :href="$store.state.opendataid.file">Скачать файл ({{$store.state.opendataid.file|nameFilter}})</a>
            </td>
          </tr>
          <tr>
            <td>6</td>
            <td>Ma'lumotlarning birinchi nashr sanasi</td>
            <td>{{ new Date($store.state.opendataid.initial_published_date).toLocaleDateString() }}</td>
          </tr>
          <tr>
            <td>7</td>
            <td>Ma’lumotlarning oxirgi nashr sanasi</td>
            <td>{{ new Date($store.state.opendataid.last_published_date).toLocaleDateString() }}</td>
          </tr>
        </table>
      </div>
      <div class="opensingcard mt-5">
        <div class="date_publication">
          <div class="date_publication_data"><span>Дата публикации:</span><p>{{$store.state.opendataid.created_on|dateFilter}}</p></div>
          <div class="date_publication_data"><span>Дата последнего обновления:</span><p>{{$store.state.opendataid.updated_on|dateFilter}}</p></div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Footer from "@/components/Header/Footer";
import Navbar from "@/components/Header/Navbar";
export default {
  components: { Footer,Navbar},
  mounted() {
    this.$store.dispatch('bujetid')
  },
  filters:{
    nameFilter(arg){
      return arg && arg.split(".")[arg.split(".").length-1]
    },
    dateFilter(e){
      let date = e && e.split("T")[0]
      let time = e && e.split("T")[1]
      let time1 = time && time.slice(0,5)
      let date1 = date && date.split("-").reverse().join(".")
      return date1 + " " + time1
    }
  }
}
</script>
